/**
* Generated automatically at built-time (2024-08-30T15:21:09.354Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "myoutdoorkitchen-nordic-line-outdoorkueche",templateKey: "sites/108-5ea4fd47-d407-4df6-8540-dda06c5f8659"};